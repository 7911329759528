import { Flex, Icon, Text } from '@chakra-ui/react';
import { ChakraNextLink } from '@ui/components/ChakraNextLink';
import { useRouter } from 'next/router';
import { type SVGProps, useMemo } from 'react';

interface NavLinkProps {
  path: string;
  activePathNames?: string[];
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  activeIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  title: string;
}

export const NavLink = ({
  path,
  activePathNames,
  icon,
  activeIcon,
  title,
}: NavLinkProps) => {
  const currentPathName = useRouter().pathname;

  const isActive = useMemo(() => {
    const pathNames = [path, ...(activePathNames ?? [])];

    return pathNames.some((p) => currentPathName.startsWith(p));
  }, [path, currentPathName, activePathNames]);

  return (
    <ChakraNextLink href={path}>
      <Flex
        flex={1}
        height="100%"
        justify="center"
        align="center"
        flexDirection="column"
      >
        <Icon
          as={isActive ? activeIcon : icon}
          width={'24px'}
          height={'24px'}
          color={'primary.cta'}
        />

        <Text
          color={isActive ? 'primary.cta' : 'primary.active_state'}
          textStyle={isActive ? 'captionBold' : 'caption'}
        >
          {title}
        </Text>
      </Flex>
    </ChakraNextLink>
  );
};
